
import React, { Component } from "react";

export default class PrivacyPolicy extends Component {

  render() {
    return (
      <div>
        <h2>Octopus Rodeo Privacy Policy NFTYCam</h2>
        <br />
        <b>Last Updated: August, 2019</b>
        <br />
With NFTYCam (the “App”), you’ll be able to to share and upload decorated photos using blockchain collectibles.  The App is available via the website, https://nftycam.com (the “Site”), or via The Apple iTunes Appstore (the “Other Formats”). The Site, the Other Formats, and any other features, tools, materials, or other services offered from time to time in relation to the Site or the Other Formats are collectively referred to here as the “Service”.  The Service is developed by Octopus Rodeo, Inc. (“Octopus Rodeo”, “we”, or “us”).
        <br />
Octopus Rodeo is committed to protecting and respecting your privacy. This Privacy Policy (this “Policy”) sets out how we collect and process personal information about you when you access or use the Service, or when you otherwise do business or make contact with us.
Please read this Policy carefully to understand how we handle and treat your personal information.
What information do we collect?
        <br />
SUMMARY:  
The Service collects data as required to enable us to operate the Services effectively, and to provide you with the best experiences with the Service. You provide some of this data to us directly, such as when you register to use our Service, subscribe to a newsletter, respond to a survey, make an enquiry through our website, contact us for support, or contact us as a prospective customer, vendor, supplier, or consultant. We get some of your data by recording how you interact with our website and the Service by, for example, using technologies like cookies. We also obtain and process data in the context of providing the Service.
        <br />
LEARN MORE:
                You have choices about the data we collect. When you are asked to provide personal data, you may decline. But if you choose not to provide data that is necessary to enable us to provide the Service, you may not be able to use all or part of the Service.
                The data we collect depends on the context of your interactions with the Service, and the choices you make (including your privacy settings).  The data we collect can include the following:
        <br />
        Username
        <br />
        SMS Number
        <br />
        Wallet Account Public Address
        <br />
        Device UDID (Advertiser ID)
        <br />
        Email and Name
        <br />
        We may collect your first and last name, email address, and other similar contact data. 
        Device and Usage information. We may collect data about your device and how you and your device interact with the Service. For example, we may collect:  
        Use and location data. We may collect data about the features you use, purchases you make, and the web pages you visit.  This may also include your interactions on our Site or on Other Formats, your feature usage patterns, location data, and your interactions with us via email.
        Device, connectivity and configuration data. We may collect data about your device and the network you use to connect to the Service. This may include data about the operating system and other software installed on your device, including product keys. It may also include IP address, browser type, operating system, and referring URLs.
        
        What do we use your information for?
        <br />
SUMMARY: 

We use the data we collect to operate our business, and to provide the Service to you.  This includes using the data to improve the Service, and to personalize your experiences.  We may also use the data to communicate with you to, among other things, inform you about your account, provide security updates, and give you information about the Service.   We may also use the data to manage your email subscriptions, improve the relevance and security of our Site or Other Formats, respond to user enquiries, send you periodic marketing communications about the Service, and improve the relevance of our advertising.
        <br />
LEARN MORE:
Providing and improving the Service. We use data to provide and improve the Service we offer, and to perform essential business operations. This includes operating the Service, maintaining and improving the performance of the Service, developing new features, conducting research, and providing customer support. Examples of such uses include the following:
Providing the Service. We use data to carry out your transactions with us and to provide the Service to you. In certain cases, the Service includes personalized features and recommendations that enhance your productivity and enjoyment, and automatically tailor your experience based on the data we have about you.
Technical support. We use data to diagnose product problems, and to provide other customer care and support services.
Improving the Service. We use data to continually improve the Service, including system administration, system security, and adding new features or capabilities. 
Business Operations. We use data to develop aggregate analyses and business intelligence that enable us to operate, protect, make informed decisions, and report on the performance of our business.
Promotions.  We may use your data to administer contests, promotions, surveys, or other site features.
Improving Advertising Campaigns.  We may use your data to improve our advertising campaigns, primarily in an effort to prevent targeting of impressions via third-party channels when they are not relevant to you.
Sending Periodic Emails.  We may use your data to send you periodic emails.  Depending on the marketing preferences you select on your privacy dashboard, we may send you occasional marketing emails about our products and services, which you can unsubscribe from at any time using the link provided in the message.
Generally.  We use data to respond to your enquiries and requests relating to the Service, to create and administer your accounts, and to provide us with information and access to resources that you have requested from us.  We also use data for general business purposes, including, among other things, to improve customer service, to help us improve the content and functionality of the Service, to better understand our users, to protect against wrongdoing, to enforce our Terms of Service https://nftycam.com/termsofuse and to generally manage our business.
Communications. We use data we collect to communicate with you, and to personalize our communications with you. For example, we may contact you to discuss your account, to let you know when updates are available, to remind you about features of the Service that are available for your use, to update you about a support request, or to invite you to participate in a survey. Additionally, you can sign up for email subscriptions, and choose whether you want to receive marketing communications from us.  
        <br />
How do we protect your information?
We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential. After a transaction, your private information (e.g., credit cards, social security numbers, financials) will not be stored on our servers.
How do we ensure that our processing systems remain confidential, resilient, and available?
        <br />
SUMMARY:
We implement a variety of measures to ensure that our processing systems remain confidential, resilient, and available.  Specifically, we have implemented processes to help ensure high availability, business continuity, and prompt disaster recovery.  We commit to maintaining strong physical and logical access controls, and conduct regular penetration testing to identify and address potential vulnerabilities. 
        <br />
LEARN MORE:
High Availability.  Every part of the Service utilizes properly-provisioned, redundant servers (e.g., multiple load balancers, web servers, replica databases) in case of failure.  We take servers out of operation as part of regular maintenance, without impacting availability.  
        <br />
Business Continuity.  We keep encrypted backups of data every hour in multiple regions on Google Cloud Platform. While never expected, in the case of production data loss (i.e., primary data stores loss), we will restore organizational data from these backups. 
        <br />
Disaster Recovery.  In the event of a region-wide outage, we will bring up a duplicate environment in a different Google Cloud Platform region. Our operations team has extensive experience performing full region migrations.  
        <br />
Physical Access Controls.  The Service is hosted on Google Cloud Platform. Google data centers feature a layered security model, including extensive safeguards such as custom-designed electronic access cards, alarms, vehicle access barriers, perimeter fencing, metal detectors, and biometrics. According to the Google Security Whitepaper: “The data center floor features laser beam intrusion detection. Data centers are monitored 24/7 by high-resolution interior and exterior cameras that can detect and track intruders. Access logs, activity records, and camera footage are reviewed in case an incident occurs. Data centers are also routinely patrolled by professional security guards who have undergone rigorous background checks and training.”  Octopus Rodeo employees do not have physical access to Google data centers, servers, network equipment, or storage.  
        <br />
Logical Access Controls.  Octopus Rodeo is the assigned administrator of its infrastructure on Google Cloud Platform, and only designated authorized Octopus Rodeo operations team members have access to configure the infrastructure on an as-needed basis behind a two-factor authenticated virtual private network. Specific private keys are required for individual servers, and keys are stored in a secure and encrypted location. 
        <br />
Penetration Testing.  Octopus Rodeo engages an independent, third-party agency to perform black box penetration testing on an annual basis.  Information about security vulnerabilities that are successfully exploited through penetration testing is then used to set mitigation and remediation priorities.
        <br />
Intrusion Detection and Prevention.  Unusual network patterns or suspicious behavior are among Octopus Rodeo’s biggest concerns for infrastructure hosting and management. Google Cloud Platform’s intrusion detection and prevention systems (IDS/IPS) rely on both signature-based and algorithm-based security to help identify traffic patterns that are similar to known attack methods.  IDS/IPS involves tightly controlling the size and make-up of the attack surface, employing intelligent detection controls at data entry points, and developing and deploying technologies that automatically remedy dangerous situations, as well as preventing known threats from accessing the system in the first place.  We do not provide direct access to security event forensics, but we do provide access to our engineering and customer support teams during and after any unscheduled downtime.  
        <br />
Do we use cookies?
        <br />
SUMMARY:
Yes. Cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information.  You can choose to disable cookies, but if you do, your ability to use or access certain parts of our website may be affected.
        <br />
LEARN MORE:
We use cookies and other similar identifiers to understand and save your preferences for future visits, to advertise to you on other sites, and to compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.
You may refuse to accept cookies by activating the setting on your browser that allows you to refuse the setting of cookies. You can find information on popular browsers and how to adjust your cookie preferences at the following websites: 
        <br />
Microsoft Internet Explorer
Mozilla Firefox
Google Chrome
Apple Safari
However, if you choose to disable cookies, you may be unable to access certain parts of the Service. A banner asking you to accept our cookies policy will be displayed upon the first visit to our website (or the first visit after you delete your cookies). Unless you have adjusted your browser setting so that it will refuse cookies and/or you have not accepted our cookies policy, our system will issue cookies when you log on to our site.
Our web pages may contain electronic images known as web beacons (also called single-pixel gifs) that we use to help deliver cookies on our websites, and to count users who have visited those websites. We may also include web beacons in our promotional email messages or newsletters, to determine whether and when you open and act on them.
In addition to placing web beacons on our own websites, we sometimes work with other companies to place our web beacons on their websites or in their advertisements. This helps us to develop statistics on how often clicking on an advertisement on the Service results in a purchase or other action on the advertiser's website.
Finally, the Service may contain web beacons or similar technologies from third-party analytics providers that help us compile aggregated statistics about the effectiveness of our promotional campaigns or other operations. These technologies enable the analytics providers to set or read their own cookies or other identifiers on your device, through which they can collect information about your online activities across applications, websites or other products. However, we prohibit these analytics providers from using web beacons on our sites to collect or access information that directly identifies you (such as your name or email address). 

        <br />
Do we disclose any information to outside parties?
        <br />
SUMMARY: 
We share your personal data with your consent, or as necessary to provide the Service to you. We also share your data with vendors working on our behalf; when required by law or to respond to legal process; to protect our customers; to protect lives; to maintain the security of the Service; and to protect our rights or our property.
        <br />
LEARN MORE:
        We share your personal data with your consent, or as necessary to provide the Service to you.  We also share personal data with vendors or agents working on our behalf for the purposes described in this Policy. For example, companies we have hired to provide cloud hosting services, off-site backups, and customer support may need access to personal data to provide those functions. In such cases, these companies are required to abide by our data privacy and security requirements and are not allowed to use personal data they receive from us for any other purpose.  The current list of vendors that we use to provide the Service can be found here https://nftycam.com/termsofuse.  We will update this list from time to time as the list of vendors changes.  If you have questions or concerns about any of our vendors, feel free to contact us at nftycam@octopusrodeo.com. 
        We may disclose your personal data as part of a corporate transaction such as a corporate sale, merger, reorganization, dissolution, or similar event.
        Finally, we will access, transfer, disclose, and/or preserve personal data, when we have a good faith belief that doing so is necessary to:
        comply with applicable law or respond to valid legal process, judicial orders, or subpoenas; 
        respond to requests from public or governmental authorities, including for national security or law enforcement purposes;
        protect the vital interests of our users, customers, or other third parties (including, for example, to prevent spam or attempts to defraud users of our products, or to help prevent the loss of life or serious injury of anyone);
        operate and maintain the security of our Services, including to prevent or stop an attack on our computer systems or networks; 
        protect the rights, interests or property of Octopus Rodeo or third parties; 
        prevent or investigate possible wrongdoing in connection with the Service; or 
        enforce our Terms of Service https://nftycam.com/termsofuse.
        We may use and share aggregated non-personal information with third parties for marketing, advertising, and analytics purposes.
        We do not sell or trade your personal information to third parties. 
        How to Access and Control Your Personal Data
        <br />
SUMMARY:
You can view, access, edit, delete, or request a copy of your personal data for many aspects of the Service via your user dashboard. You can also make choices about the Service’s collection and use of your data.
You can always choose whether you want to receive marketing communications from us. You can also opt out from receiving marketing communications from us by using the opt-out link on the communication, or by visiting your user dashboard.
        <br />
LEARN MORE:
Data Access.  You can access your personal data on your account’s user dashboard. 
Data Portability. You can request a copy of your personal data by submitting an email to us at nftycam@octopusrodeo.com and including “Please send me a copy of my personal data” in the “Subject” line.  Octopus Rodeo will verify your ability to access that email, then send you a digital export of the data we hold that is associated with your email address.   We will use reasonable efforts to respond to your request within 14 days, but in all events within 30 days of our receipt of the request.  
Data Erasure.  You can delete your personal data on your account’s user dashboard.  Please be aware that we require certain information about you in order to provide the Service; this means that if you want to delete those critical pieces of personal data, you may be required to delete your entire profile and no longer be able to access or use the Services.  Alternatively, you may request that Octopus Rodeo delete your personal data by submitting an email to us at nftycam@octopusrodeo.comand including “Please delete my personal data” in the “Subject” line.  Octopus Rodeo will verify your ability to access that email, then delete the personal data associated with your email address.  All customer data stored on our servers is eradicated upon a customer’s termination of service and deletion of account after a 24-hour waiting period to prevent accidental cancellation.  We will use reasonable efforts to respond to your request within 14 days, but in all events within 30 days of our receipt of the request.  Please note that we retain usage metadata about a company or individual as required for compliance with law and regulation.  
Data Correction.  You can modify your personal data on your account’s user dashboard.  
 Your Communications Preferences.  You can choose whether you wish to receive marketing communications from us. If you receive marketing communications from us and would like to opt out, you can do so by following the directions in that communication. You can also make choices about your receipt of marketing communications by signing into your account, and viewing and managing your communication permissions in your account’s user dashboard, where you can update contact information, manage your contact preferences, opt out of email subscriptions, and choose whether to share your contact information with the Service and our partners. Alternatively, you can request that we withdraw consent to use your personal data by submitting an email to us at nftycam@octopusrodeo.com, and including “Please withdraw my consent for marketing communications” in the “Subject” line. Octopus Rodeo will verify your ability to access that email, then update our systems to remove your email address from the system we use to send marketing communications. We will use reasonable efforts to respond to your request within 14 days, but in all events within 30 days of our receipt of the request.  Please note that these choices do not apply to mandatory communications that are part of the Service, or to surveys or other informational communications that have their own unsubscribe method.
        <br />
Third Party Links
Occasionally, at our discretion, we may include or offer third party products or services on our website or through the Service. If you access other websites using the links provided, the operators of these websites may collect information from you that will be used by them in accordance with their privacy policies. These third party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
Where we Store and Process Personal Data; International Transfers
Personal data collected by the Service may be stored and processed in Canada or in any other country where Octopus Rodeo or its affiliates, subsidiaries or service providers maintain facilities.  The storage location(s) are chosen in order to operate efficiently, to improve performance, and to create redundancies in order to protect the data in the event of an outage or other problem. We take steps to ensure that the data we collect is processed according to the provisions of this Policy, and the requirements of applicable law wherever the data is located.
We transfer personal data from the European Economic Area and Switzerland to other countries, some of which have not been determined by the European Commission to have an adequate level of data protection. When we engage in such transfers, we use a variety of legal mechanisms, including contracts, to help ensure your rights and protections travel with your data. To learn more about the European Commission’s decisions on the adequacy of the protection of personal data in the countries where Octopus Rodeo processes personal data, please visit: ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.htm
        <br />
Data Retention
        <br />
SUMMARY:
We may retain your personal information as long as you continue to use the Service, have an account with us, or for as long as is necessary to fulfill the purposes outlined in this Policy. You can ask to close your account by contacting us as described above, and we will delete your personal information on request.
We may, however, retain personal information for an additional period as is permitted or required under applicable laws, for legal, tax, or regulatory reasons, or for legitimate and lawful business purposes.
        <br />
LEARN MORE:
We will retain your personal data for as long as necessary to provide the Service to you, or for other essential purposes such as complying with our legal obligations, resolving disputes, and enforcing our agreements. Because these needs can vary for different types of data, actual retention periods can vary significantly. The criteria we use to determine the retention periods include:
How long is the personal data needed to provide the Service and/or operate our business? This includes such things such as maintaining and improving the performance of the Service, keeping our systems secure, and maintaining appropriate business and financial records. This is the general rule that establishes the baseline for most data retention periods.
Is there an automated control, such as in your user dashboard, that enables you to access and delete the personal data at any time? If there is not, a shortened data retention time will generally be adopted.
Is the personal data of a sensitive type? If so, a shortened retention time would generally be appropriate.
Has the user provided consent for a longer retention period? If so, we will retain the data in accordance with your consent.
Is Octopus Rodeo subject to a legal, contractual, or similar obligation to retain the data? Examples can include mandatory data retention laws in the applicable jurisdiction, government orders to preserve data relevant to an investigation, or data that must be retained for the purposes of litigation.
        <br />
Changes to our Privacy Policy
Octopus Rodeo will update this privacy statement when necessary to reflect customer feedback and changes in the Service. When we post changes to this statement, we will revise the "last updated" date at the top of the statement. If there are material changes to the statement or in how Octopus Rodeo will use your personal data, we will notify you either by prominently posting a notice of such changes before they take effect or by sending you a notification directly. We encourage you to periodically review this privacy statement to learn how Octopus Rodeo is protecting your information.
        <br />
How to Contact Us
        <br />
If you have a technical or support question, please send us an email at nftycam@octopusrodeo.com.
If you have a privacy concern, complaint, or a question for the Data Protection Officer of Octopus Rodeo, please contact us by sending us an email at nftycam@octopusrodeo.com. We will respond to questions or concerns within 30 days.
Unless otherwise stated, Octopus Rodeo is a data controller for personal data we collect through the Service subject to this statement. Our address is Octopus Rodeo, Inc., 600-565 Great Northern Parkway, Vancouver, BC, Canada V5T 0H8.

      </div>
                                                                
    )
  }
}