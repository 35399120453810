
export const Theme = {

  header1: {
    fontWeight: 900,
    fontSize: "22px",
    marginBottom: "20px"
  },

  header2: {
    fontWeight: 900,
    fontSize: "28px",
    marginBottom: "20px"
  },

  button: {
    borderRadius: 20,
    height: 40,
    paddingLeft: 50,
    paddingRight: 50,
    fontWeight: "bold",
    backgroundColor: "darkorchid",
    border: 0
  },

  buttonSecondary: {
    color: "black",
    borderRadius: 20,
    paddingLeft: 25,
    paddingRight: 25,
    fontWeight: "bold",
    backgroundColor: "white",
    fontSize: "14px",
    border: "1px solid rgba(154, 159, 167, 0.3)",
  },
  
  textFieldLabel: {
    fontSize: "12px",
    color: "grey",
    fontWeight: "bold",
  },

  textField: {
    border: "0",
    borderRadius: "8px",
    height: 50,
    boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
  },

  dropButton: {
    border: "0",
    borderRadius: "8px",
    height: 50,
    boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
  },

  textFieldLink: {
    fontSize: "12px",
    color: "darkgrey",
    fontWeight: "bold",
  },

  link: {
    color: "black",
    fontWeight: "bold"
  },

  floatNav: {
    margin: "auto",
    padding: "20px",
    marginBottom: "20px",
    display: "flex",
    justifyContent: "space-between",
  },

  floatContainer: {
    width: 480,
    margin: "auto",
    borderRadius: "8px",
    boxShadow: "0px 0px 20px rgba(0,0,0,0.10)",
    padding: "20px",
    marginBottom: "20px",
    border: "1px solid rgba(154, 159, 167, 0.3)",
  },

  floatContainerHeader: {
    fontSize: "12px",
    fontWeight: "bold",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    backgroundColor: "#F7F7F7",
    marginTop: "-20px",
    marginLeft: "-20px",
    marginRight: "-20px",
    marginBottom: "20px",
    textAlign: "center",
    padding: 15,
    borderBottom: "1px solid rgba(154, 159, 167, 0.3)",
  },

  // FONTS
  finePrint: {
    fontSize: "12px",
    color: "lightgrey"
  },

  navLink: {
    fontSize: "16px",
    color: "grey",
    fontWeight: "bold"
  }
  
}

export const Align = {
  center: {
    margin: "auto",
    textAlign: "center",
    paddingTop: 10,
  },

  right: {
    textAlign: "right",
  }

}