import React, { Component } from "react";
import * as Style from "../theme"; 

import PreviewImage from "../assets/app-preview.png";
import AppBadge from "../assets/appstoreBadge.png";

import Partner_Dapper from "../assets/partner_dapper.png";
import Partner_Opensea from "../assets/partneropensea.png";

export default class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      user: null,
    }
  }

  logOutUser = () => {
  }

  async componentWillMount() {
    this.init();
  }

  init() {
    console.log(this.state)
  }

  render() {
    return (
      <div>

        <div style={Style.Align.center}>
          <h1 style={Style.Theme.header2}>NFTYCam</h1>
          <a href="https://apps.apple.com/app/id1478164249?ls=1"><img style={styles.img}  src={AppBadge} height={60} /></a>
          <p>Snap, slap, and stick your favorite Collectibles</p>
          <img src={PreviewImage} width={400} />
          <p>🐙</p>
          <p><b>Built by the <a href="mailto:nftycam@octopusrodeo.com">Octopus Rodeo</a></b></p>
           <h1 style={Style.Theme.finePrint}>Powered by</h1>
          <div>
            <img style={styles.img} src={Partner_Dapper} height={60} />
            <img style={styles.img} src={Partner_Opensea} height={60} />
          </div> 
        </div>
      </div>
    )
  }
}

const styles = {
  img: {
    marginRight: 10,
    marginLeft: 10,
    marginBottom: 20,
  }
}



