import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

// Define Pages
import Landing from "./pages/Landing";
import TermsOfUse from "./pages/Terms";
import PrivacyPolicy from "./pages/Privacy";

export default class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loggedIn: false,
      user: "",
      isChrome: false,
    }
  }

  componentWillMount() {
    var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    this.setState({isChrome: isChrome});
  }

  render() {
    return (
      <Router>
        <div style={styles.container}>
          <Route path="/" exact component={Landing} />
          <Route path="/termsofuse" exact component={TermsOfUse} />
          <Route path="/privacypolicy" exact component={PrivacyPolicy} />
          <div style={{ "textAlign": "center", "fontSize": "10px", "marginBottom": "10px" }}>
            <Link to={"/termsofuse"}> Terms of Use </Link>
            |
            <Link to={"/privacypolicy"}> Privacy Policy </Link>
            | © Octopus Rodeo 2019
          </div>
        </div>
      </Router>
    )
  }
}

const styles = {
  container:{
    width: 650,
    paddingTop: "40px",
    margin: "auto",
  },

  chromeAlert: {
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    marginBottom: 20,
    textAlign: "center",
  }
}

